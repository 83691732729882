<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card
          no-body
          class="card__ p-2"
        >
          <b-tabs
            pills
            vertical
          >
            <b-tab
              title="Desayuno"
              active
            >
              <breakfast-form
                :dishes="dishes"
                :isbusy="isBusy"
                @formSubmitted="formSubmitted"
              />
            </b-tab>
            <b-tab title="Almuerzo">
              <lunch-form :dishes="dishes" />
            </b-tab>
            <b-tab title="Cena">
              <dinner-form :dishes="dishes" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import BreakfastForm from './addForms/BreakfastForm'
import LunchForm from './addForms/LunchForm'
import DinnerForm from './addForms/DinnerForm'

export default {
  inject: ['disheRepository', 'menuRespository'],
  components: {
    BCard,
    BRow,
    BCol,
    BreakfastForm,
    LunchForm,
    DinnerForm,
    BTabs,
    BTab,
  },
  data() {
    return {
      dishes: null,
      breakfasts: null,
      lunches: null,
      dinners: null,
      isBusy: false,
    }
  },
  mounted() {
    const me = this

    me.loadDishes()
    me.loadMenus()
  },
  methods: {
    async formSubmitted(data) {
      const me = this
      try {
        me.isBusy = true
        const uuid = uuidv4()
        await me.menuRespository.save(uuid, data)
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBusy = false
      }
    },
    async loadDishes() {
      const me = this
      try {
       const dishes = await me.disheRepository.getAll()
        me.dishes = dishes.data
      } catch (error) {
        // console.log(error)
      }
    },
    async loadMenus() {
      const me = this
      try {
        const menus = await me.menuRespository.getAll()
        const { data } = menus

        const breakfasts = data.filter(el => el.type_menu == 1)
        const lunches = data.filter(el => el.type_menu == 2)
        const dinners = data.filter(el => el.type_menu == 3)

        me.breakfasts = breakfasts.items
        me.lunches = lunches.items
        me.dinners = dinners.items
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
.card__ {
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
}
</style>
