<template>
  <div>
    <template v-if="items">
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <b-form-group>
            <v-select
              v-model="breakfasts"
              multiple
              :options="items"
              label="name"
              placeholder="Seleccione platos"
            >
              <template #option="{ name }">
                <img
                  src="@/assets/images/pescado.jpg"
                  :alt="name"
                  width="10%"
                >
                <span> {{ name }}</span>
              </template>
            </v-select>
            <span
              v-if="$v.breakfasts.$error && !$v.breakfasts.required"
              class="help-block text-danger"
            >Campo requerido</span>
          </b-form-group>
        </b-col>
        <b-col
          v-for="(item, index) in breakfasts"
          :key="index"
          cols="6"
          class="mb-2"
        >
          <b-row>
            <b-col cols="12">
              <b-card
                no-body
                class="p-1 card__"
              >
                <b-row>
                  <b-col cols="12">
                    <div class="title text-center">
                      {{ item.name }}
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <img
                      src="@/assets/images/pescado.jpg"
                      alt=""
                      width="100%"
                      class="img__"
                    >
                  </b-col>
                  <b-col
                    cols="6"
                    class="p-0"
                  >
                    <div class="title2 text-center">
                      Informaciòn Nutricional
                    </div>
                    <div
                      v-for="(inf) in item.info"
                      :key="inf.name"
                      class="title2"
                    >
                      <v-icon
                        name="check-circle"
                      />
                      {{ inf.name }}
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isbusy || $v.$invalid"
              @click="save"
            >
              <b-spinner
                v-if="isbusy"
                small
              />
              <v-icon
                v-else
                name="save"
              />
              Guardar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-50" />
        <strong>Cargando...</strong>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BCard,
    BSpinner,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    dishes: {
      type: Array,
      default: null,
    },
    isbusy: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      breakfasts: null,
      items: this.dishes,
    }
  },
  watch: {
    dishes(newData) {
      this.items = newData
    },
  },
  validations: {
    breakfasts: {
      required,
    },
  },
  methods: {
    save() {
      const menu_items = []
      this.breakfasts.map(el => menu_items.push({ food_id: el.id }))
      const data = {
        type_menu: '1',
        date: '2021-10-24',
        menu_items: JSON.stringify(menu_items),
      }
      this.$emit('formSubmitted', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.card__ {
  box-shadow: 0 5px 11px 0 rgba(100, 100, 100, 0.3), 0 4px 15px 0 rgba(77, 76, 76, 0.15) !important;
}

.title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 8px;
  color: black;
}

.title2 {
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
}

.img__ {
  height: 137px !important;
  margin-bottom: 5px !important;
}
</style>
